import { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, PortalRoot, theme, ThemeProvider } from '@m/alchemy-ui';
import styled from 'styled-components';
import { StyledDialogBody, StyledIframe, StyledProgress } from './StyledComponents';

interface InsertImageDialogProps {
  readonly insertImageHandler: InsertImageHandler;
}

export type InsertImageHandler = (event: CustomEvent<string> | Event) => void;

const StyledDialog = styled(Dialog)`
  z-index: 1050;
`;

export const ImageInsertDialog = ({ insertImageHandler }: InsertImageDialogProps) => {
  const IFRAME_SRC = 'file/index?type=image&mode=radio&updateTarget=editor';
  const DIALOG_WIDTH = '80%';
  const DIALOG_HEIGHT = '80%';

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(true);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(true);

  const closeDialog = () => {
    setIsImageDialogOpen(false);
  };

  const hideSpinner = () => {
    setIsSpinnerVisible(false);
  };

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const dialogCloseCallback = useCallback(
    (event: CustomEvent<string> | Event) => {
      setIsImageDialogOpen(false);
      insertImageHandler(event);
    },
    [insertImageHandler]
  );

  useEffect(() => {
    window.addEventListener('ImageUploaderDialogClose', dialogCloseCallback);

    return () => {
      window.removeEventListener('ImageUploaderDialogClose', dialogCloseCallback);
    };
  }, [dialogCloseCallback]);

  return (
    <ThemeProvider theme={theme}>
      <PortalRoot>
        <StyledDialog
          isOpen={isImageDialogOpen}
          onClose={closeDialog}
          hasCloseIcon
          headerContent="Insert image"
          width={DIALOG_WIDTH}
          height={DIALOG_HEIGHT}
          isConstrainedToPortalRoot
        >
          <StyledDialogBody>
            {isSpinnerVisible ? <StyledProgress /> : null}
            <StyledIframe data-testid="ImageInsertDialogIframe" ref={iframeRef} src={IFRAME_SRC} onLoad={hideSpinner} />
          </StyledDialogBody>
        </StyledDialog>
      </PortalRoot>
    </ThemeProvider>
  );
};
