import { initTextEdit } from './common/editorInit';
import { type StringMap } from './interfaces/editor';

export { CrowdicityTextEditor as default } from './CrowdicityTextEditor';

declare global {
  interface Window {
    TEXT_EDITOR_COMPONENTS: {
      initTextEdit: (stringMap?: StringMap) => void;
    };
  }
}

window.TEXT_EDITOR_COMPONENTS = {
  initTextEdit
};
