import { flushSync } from 'react-dom';
import { type StringMap } from '../interfaces/editor';
import { getTextEditorConfig } from './editorConfig';
import { renderNode, renderTextInputs } from './editorRenderHelpers';

const TEXT_EDIT_CLASS = 'alchemy-text-edit';
const TEXT_EDIT_INITIALIZED_CLASS = 'alchemy-text-edit-initialized';

export const initTextEdit = (stringMap?: StringMap, isInitial?: boolean) => {
  const textAreaSelector = `textarea.${TEXT_EDIT_CLASS}:not(.${TEXT_EDIT_INITIALIZED_CLASS})`;
  const textInputs = document.querySelectorAll(textAreaSelector);
  return renderTextInputs(textInputs, stringMap, isInitial);
};

export const initializeInput = (
  textInput: Element | HTMLElement,
  stringMap?: StringMap,
  isInitialRendering: boolean = true
) => {
  const preparedConfig = getTextEditorConfig(textInput);
  // clone config: page can contain multiple editors with different sets of buttons
  const config = { ...preparedConfig };
  const domNode = document.createElement('div');
  textInput.parentNode?.insertBefore(domNode, textInput.nextSibling);

  // TODO: this seems wrong, should be able to just used text area value
  const value =
    (isInitialRendering ? textInput.textContent : textInput.querySelector('[contenteditable=true]')?.innerHTML) || '';
  // we should keep original id for textarea - legacy code uses it for saving
  domNode.id = textInput.id + '-text-editor';

  // Adding this class indicates that the textarea is now alchemified
  domNode.className += `${TEXT_EDIT_CLASS} alchemy-editor-container`;

  // add initialized class before we take the element attrs off so we don't have to smash things in after.
  textInput.classList.add(TEXT_EDIT_INITIALIZED_CLASS);

  const textAreaAttributes = Object.fromEntries(
    textInput.getAttributeNames().map((name) => [name, textInput.getAttribute(name)])
  );

  let result;

  // we need flushSync here because legacy code is taking care of hiding text areas for each translations, and all ot them should be in the DOM before starting the legacy code stuff
  flushSync(() => {
    // do we actually have to remove the node?
    textInput.remove();
    result = renderNode({
      initialValue: value,
      domNode,
      config,
      textAreaAttrs: textAreaAttributes,
      stringMap
    });
  });

  return { [textInput.id]: result };
};
