import { type CrowdicityTextEditorProps } from '../interfaces/editor';

const html2dom = {
  acceptcharset: 'acceptCharset',
  accesskey: 'accessKey',
  bgcolor: 'bgColor',
  cellindex: 'cellIndex',
  cellpadding: 'cellPadding',
  cellspacing: 'cellSpacing',
  choff: 'chOff',
  class: 'className',
  codebase: 'codeBase',
  codetype: 'codeType',
  colspan: 'colSpan',
  datetime: 'dateTime',
  checked: 'defaultChecked',
  selected: 'defaultSelected',
  value: 'defaultValue',
  frameborder: 'frameBorder',
  httpequiv: 'httpEquiv',
  longdesc: 'longDesc',
  marginheight: 'marginHeight',
  marginwidth: 'marginWidth',
  maxlength: 'maxLength',
  nohref: 'noHref',
  noresize: 'noResize',
  noshade: 'noShade',
  nowrap: 'noWrap',
  readonly: 'readOnly',
  rowindex: 'rowIndex',
  rowspan: 'rowSpan',
  sectionrowindex: 'sectionRowIndex',
  selectedindex: 'selectedIndex',
  spellcheck: 'spellCheck',
  tabindex: 'tabIndex',
  tbodies: 'tBodies',
  tfoot: 'tFoot',
  thead: 'tHead',
  url: 'URL',
  usemap: 'useMap',
  valign: 'vAlign',
  valuetype: 'valueType'
} as { [key: string]: string };

export const convertAttrs = (
  attrs: CrowdicityTextEditorProps['textAreaAttrs'] = {}
): CrowdicityTextEditorProps['textAreaAttrs'] => {
  const keyValueAttrs = Object.entries(attrs).map(([k, v]) => [html2dom[k] || k, v]);
  // we've typed the output of the function. we should be able to make ts infer this but not enough time right now
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.fromEntries(keyValueAttrs);
};
