import { type BaseEditor, type Node } from 'slate';
import { ImageIcon, SlateRTE } from '@m/alchemy-ui';
import { renderImageInsertDialog } from './editorRenderHelpers';

// eslint-disable-next-line react/jsx-curly-brace-presence
const TagIcon = () => <span>{'Tag'}</span>;

export const insertImage = () => ({
  name: 'Crowdicity Image Insert',
  icon: <ImageIcon aria-hidden="true" />,
  label: 'Insert image',
  callback: (editor: BaseEditor) => {
    const insertImageHandler = (event: CustomEvent<string> | Event) => {
      let imageUrl;
      if ('detail' in event) {
        imageUrl = event.detail;
      }

      if (imageUrl) {
        editor.insertNode(SlateRTE.deserialize(`<img src='${imageUrl}' />`) as unknown as Node);
      }
    };

    renderImageInsertDialog(insertImageHandler);
  }
});

export const insertUserNameTag = () => ({
  name: 'Tag',
  icon: <TagIcon />,
  label: 'Tag user',
  callback: (editor: BaseEditor) => {
    editor.insertNode(SlateRTE.deserialize('<span>[[USER_NAME]]</span>') as unknown as Node);
  }
});
