import { createRoot } from 'react-dom/client';
import { ImageInsertDialog, type InsertImageHandler } from '../ImageInsertDialog/ImageInsertDialog';
import { type CrowdicityTextEditorProps, type RichTextEditorProps, type StringMap } from '../interfaces/editor';
import { CrowdicityTextEditor } from '../CrowdicityTextEditor';
import { initializeInput } from './editorInit';

const DIALOG_ID = 'imageInsertDialog';
const rootMap = new Map();

export const renderImageInsertDialog = (insertImageHandler: InsertImageHandler) => {
  let domNode = document.querySelector(`#${DIALOG_ID}`);

  if (rootMap.has(DIALOG_ID)) {
    const existingRoot = rootMap.get(DIALOG_ID);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    existingRoot.unmount();
    rootMap.delete(DIALOG_ID);
  }

  if (!domNode) {
    domNode = document.createElement('div');
    domNode.id = DIALOG_ID;
    document.body.appendChild(domNode);
  }

  const root = createRoot(domNode);
  root.render(<ImageInsertDialog insertImageHandler={insertImageHandler} />);
  rootMap.set(DIALOG_ID, root);
};

export const renderTextInputs = (
  textInputs: HTMLElement[] | NodeListOf<Element>,
  stringMap?: StringMap,
  isInitialRendering?: boolean
) => {
  const inputs = Array.from(textInputs).map((textInput) => initializeInput(textInput, stringMap, isInitialRendering));
  return inputs.reduce<ReturnType<typeof initializeInput>>(
    (inputsById, inputById) => ({ ...inputsById, ...inputById }),
    {}
  );
};

export const renderNode = ({
  initialValue,
  domNode,
  config,
  textAreaAttrs,
  stringMap
}: {
  initialValue: string;
  domNode: HTMLDivElement;
  config: RichTextEditorProps;
  errorMsg?: string;
  textAreaAttrs?: CrowdicityTextEditorProps['textAreaAttrs'];
  stringMap?: StringMap;
}) => {
  const nodeId = domNode.id;
  const value = initialValue;
  const root = createRoot(domNode);

  root.render(
    <CrowdicityTextEditor
      initialValue={initialValue}
      nodeId={nodeId}
      config={config}
      textAreaAttrs={textAreaAttrs}
      stringMap={stringMap}
    />
  );

  return {
    rerender: ({ initialValue = value }: { initialValue?: string; errorMsg?: string } = {}) => {
      root.render(
        <CrowdicityTextEditor
          initialValue={initialValue}
          nodeId={nodeId}
          config={config}
          textAreaAttrs={textAreaAttrs}
          stringMap={stringMap}
        />
      );
    },
    unmount: () => root.unmount()
  };
};
