import { useEffect, useMemo, useRef, useState } from 'react';
import { AlchemyProvider, NAMESPACE, SlateRTE } from '@m/alchemy-ui';
import { convertAttrs } from './common/convert-attrs';
import { type CrowdicityTextEditorProps } from './interfaces/editor';

export const CrowdicityTextEditor = ({
  initialValue,
  nodeId,
  config,
  textAreaAttrs,
  stringMap
}: CrowdicityTextEditorProps) => {
  const [defaultValue, setDefaultValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);
  const textArea = useRef<HTMLTextAreaElement | null>(null);

  const attrsToAssign = useMemo(() => {
    // we don't want to copy style
    const { style, ...others } = textAreaAttrs || {};
    return convertAttrs(others);
  }, [textAreaAttrs]);

  useEffect(() => {
    if (!textArea.current) {
      return;
    }

    const node = textArea.current;
    const handler = (e: Event) => {
      setDefaultValue((e?.target as HTMLTextAreaElement).value);
    };

    // because we dispatch events in vanilla js, React won't listen to them, so we must use DOM API insteadz.
    node.addEventListener('input', handler);
    return () => node.removeEventListener('input', handler);
  });

  useEffect(() => {
    if (!textArea.current) {
      return;
    }

    const node = textArea.current;
    const event = new Event('change', { bubbles: true });
    node?.dispatchEvent(event);
  }, [value]);

  const charLimit = textAreaAttrs?.['data-gbcharacterlimit'];
  const hasCharLimit = Boolean(charLimit);
  // we skip tags and only count characters
  const charsRemaining = hasCharLimit ? Number(charLimit) - value.trim().replace(/(<([^>]+)>)/gi, '').length : NaN;
  const { buttons } = config;

  return (
    <AlchemyProvider namespace={NAMESPACE}>
      <div className={NAMESPACE}>
        {/* Alchemy wants <GlobalStyles /> here, but including global styles will conflict with existing crowdicity styling */}
        <SlateRTE
          onChange={(val) => setValue(SlateRTE.serialize(val))}
          controls={buttons}
          initialValue={SlateRTE.deserialize(defaultValue)}
          key={nodeId}
        />
        {hasCharLimit && (
          <p className="small lightGrey">
            <strong style={charsRemaining < 0 ? { color: 'red' } : undefined}>{charsRemaining} </strong>
            {stringMap?.characterCount || 'characters remaining'}
          </p>
        )}
        <textarea {...attrsToAssign} ref={textArea} value={value} readOnly style={{ display: 'none' }} />
      </div>
    </AlchemyProvider>
  );
};
