import styled from 'styled-components';
import { CircularProgress, Dialog } from '@m/alchemy-ui';

export const StyledProgress = styled(CircularProgress)`
  position: absolute;
`;

export const StyledIframe = styled.iframe`
  width: 98%;
  height: 98%;
  border: unset;
`;

export const StyledDialogBody = styled(Dialog.Body)`
  justify-content: center;
  flex: auto;
  display: flex;
  align-items: center;
`;
