import { type SlateToolbarControl } from '@m/alchemy-ui';
import { type RichTextEditorProps } from '../interfaces/editor';
import { insertImage, insertUserNameTag } from './editorCustomButtons';

const IMAGE_INSERT_BUTTON = 'crwdImage';
const TAG_BUTTON = 'tag';

export type TextEditorCustomButtons = typeof IMAGE_INSERT_BUTTON | typeof TAG_BUTTON;

const defaultProps: RichTextEditorProps = {
  buttons: ['bold', 'italic', 'unorderedList', 'undo', 'redo']
};

export const getTextEditorConfig = (htmlElement: Element | HTMLElement | null): RichTextEditorProps => {
  const config = { ...defaultProps };
  if (htmlElement instanceof HTMLElement) {
    const { buttons } = htmlElement.dataset;
    const buttonsData: Array<TextEditorCustomButtons> = buttons ? JSON.parse(buttons) : [];
    const buttonsFormatted: Array<SlateToolbarControl> = buttonsData.map(
      (button: SlateToolbarControl | TextEditorCustomButtons) => {
        if (button === TAG_BUTTON) {
          return insertUserNameTag();
        }

        if (button === IMAGE_INSERT_BUTTON) {
          return insertImage();
        }

        return button;
      }
    );

    if (buttonsFormatted.length > 0) {
      config.buttons = buttonsFormatted;
    }
  }
  return config;
};
